.container{
  display: flex;
  width: 100%;
  height: 100%;
  min-width: 1280px;
  flex-direction: row;
  overflow: hidden;
  &__content{
    width: 100%;
  }
}

.progressBar {
  position: absolute;
  width: 100%;
  height: 2px;
  background: blue;
  bottom: -2px;
  left: 0;
}

.limitBar {
  height: 31px;
  color: #912278;
  background-color: #f1e8f2;
  font-family: Manrope, Regular;
  font-size: 14px;
  line-height: 31px;
  text-align: center;
  position: relative;
  z-index: 999;
}

.limitBar >a{
  cursor: pointer;
  text-decoration: underline;
  color: blue;
}