.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 35px 30px 20px 30px;
  background: white;
  border-radius: 8px;
  &__close {
    position: absolute;
    right: 20px;
    top: 10px;
    cursor: pointer;
    font-family: Manrope;
  }
}
