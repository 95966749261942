.drag_drop {
  position: relative;

  .uppy-Dashboard-AddFiles {
    justify-content: flex-start;
    padding: 100px 137px 0px!important;
    margin: 0px;
    border: 0px !important;
    font-family: 'Helvetica Neue', sans-serif;
  }
  .uppy-Dashboard-AddFiles-title {
    font-size: 36px !important;
    text-align: center !important;
    letter-spacing: 0px !important;
    color: #707070 !important;
  }

  .uppy-Dashboard-browse {
    color: #0081b7;
    font-weight: 400 !important;
  }

  .uppy-Dashboard-inner {
    background-color: transparent;
    border: 0px;
  }
  .uppy-Dashboard-dropFilesHereHint {
    background-color: rgba(237, 237, 240, 1);
    width: 100%;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    border-radius: 0px;
    border: 1px solid transparent;
  }
}

.empty_file{
  display: flex;
  background-color: #ffffff;
  align-items: flex-start;
  position: relative;

  min-height: 750px;
  flex-direction: row;
  margin: 0 50px 0 50px;
  padding: 50px;
  .title {
    font-size: 18px;
    font-weight: 300;
    margin-bottom: 12px;
  }


  .description {
    text-align: center;
    font-size: 12px;
    font-weight: 300;
  }
  .title_wrapper{
    flex-basis: 30%;
  }

  .title{
    color: #707070;
    font-size: 36px;
    letter-spacing: 0px;
    font-weight: 500;
  }
  .upload_wrapper{
    border: 2px dashed #0080b7b0;
    position: relative;
    cursor: pointer;
  }
  
  .right{
    flex-basis: 70%;
  }

  .upload_title{
    font-size: 36px;
    line-height: 90px;
    color: #707070;
    text-align: center;
  }

  .brows_local{
    color: #0081B7;
    cursor: pointer;
  }

  .social_wrapper{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-top: 100px;
    gap: 50px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50% , -50%);
  }

  .social_text{
     color:#000000;
     font-size: 12px;
     font-weight: bold;
  }
  .social_content{
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    cursor: pointer;
  } 

  .info_wrapper{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 17px;
    justify-content: center;
  }

  .info_text{
    color: #707070;
    font-size: 14px;
    font-weight: 500;
  }

  .info_modal {
    cursor: pointer;
  }
 
}
.emty_file_disabled_icon{
  cursor: not-allowed;
}
.emty_file_disabled{
  background: #AFB9C29C 0% 0% no-repeat padding-box;
  opacity: .5;
  pointer-events: none;
}


.bottomWindowModal {
  width: 95%;
  background: white;
  padding: 0 0 35px;

  .bottomWindowModal_title {
    font-weight: bold;
    font-size: 22px;
    margin: 20px 0;
    font-family: 'Manrope', sans-serif;
    font-weight: 900;
    color: #20303E;
  }

  .bottomWindowModal_divider {
   height: 0.1px;
   width: 100%;
   background: #E7EAEF;
   margin-bottom: 15px;
  }

  h4 {
    font-family: 'Manrope', sans-serif;
    font-size: 14px;
    font-weight: 300;
    margin-bottom: 5px;
    margin-top: 23px;
    color: #20303E;
  }

  p {
    margin: 7px 0;
    font-size: 10px;
    color: #20303E;
    font-family: 'Manrope';
    opacity: 1;
  }
}

@media only screen and (max-width: 1600px) {
  .drag_drop {
    .uppy-Dashboard-AddFiles-title {
      font-size: 36px !important;
      line-height: 50px !important;
      color: #707070 !important;
      font-weight: 400 !important;
    }
    .uppy-Dashboard-AddFiles {
      padding-left: 50px !important;
      padding-right: 50px !important;
    }
  }
}
