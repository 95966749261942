.input {
  background-color: #f4f5f9;
  border: 0;
  padding: 17px 85px 17px 17px;
  width: 347px;
  height: 56px;
  border-radius: 10px;
  font-size: 15px;
  color: #081d34;
  position: relative;
  min-width: 150px;
}

//remove number spinner
.input::-webkit-inner-spin-button,
.input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.input:focus {
  border: 1px solid #0089ed;
}
.inputDisabled {
  background-color: #f4f5f9;
  border: 0;
  padding: 17px 85px 17px 17px;
  width: 347px;
  height: 56px;
  border-radius: 10px;
  font-size: 15px;
  color: #7e8993;
  position: relative;
  min-width: 150px;
}
input:focus {
  border: #7e8993;
  outline: none;
}
// .label{
//   margin-bottom: 10px;
//   margin-top: 20px;
//   font-size: 12px;
//   font-family: 'Manrope Regular',serif;
//   color: #081D34;
// }
// .labelDisabled{
//   margin-bottom: 10px;
//   margin-top: 20px;
//   font-size: 14px;
//   font-family: 'Manrope Regular',serif;
//   color: #AFB9C2;

// }
