.button {
  display: flex;
  cursor: pointer;
  flex-direction: row;
  align-items: center;
  height: 41px;
  border-radius: 25px;
  padding: 0 26px;
  justify-content: center;
  &__arrow {
    width: 7px;
    height: 7px;
    border-right: 1px solid #ffffff;
    border-bottom: 1px solid #ffffff;
    transform: rotate(45deg);
    margin-left: 7px;
    margin-bottom: 3px;
  }
  &__title {
    color: white;
    font-family: "Manrope Regular", serif;
  }
  &__plusIcon {
    color: white;
    margin-right: 8px;
  }
}

.buttonDisabled {
  cursor: not-allowed;
  background-color: #E7EAEF !important;

  .button {
    &__arrow {
      border-right: 1px solid #AFB9C2;
      border-bottom: 1px solid #AFB9C2;
    }
    &__title {
      color: #AFB9C2 !important;
    }
    &__plusIcon {
      color: #AFB9C2 !important;
    }
  }
}

.blue {
  background: #0093ff;
}
.blue:hover {
  background: #0073c6;
}
.violet {
  background: #bf41a1;
}
.violet:hover {
  background: #912278;
}

.whiteText {
  color: #081d34;
  font-family: Manrope;
}
.white {
  background: white;
  color: #081d34;
  border: 1px solid #081d34;
  font-family: Manrope;
}
.grey {
  background: #e7eaef;
}
.greyText {
  color: #afb9c2;
}
.spinner {
  margin-left: 15px;
  margin-right: 15px;
}
