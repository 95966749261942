.page {
  display: flex;
  flex-direction: column;
}

.button {
  margin: 0 auto;
  margin-top: 20px;
}

.input {
  margin: 0 auto;
  margin-top: 20px;
}
