.loaderWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.smallLoaderWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50px;
}
.text {
  color: #7e8993;
  font-size: 12px;
  margin-top: 5px;
  text-align: center; 
}

.loader {
  border: 2px solid #f3f3f3; /* Light grey */
  border-top: 2px solid #3498db; /* Blue */
  border-radius: 100%;
  width: 21px;
  height: 21px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
