@import "../routes/kapp/components/bottom-wrapper/dragDrop.scss";
html,
body {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  background: #fafafa;
  font-family: "Manrope", sans-serif;
  font-weight: 400;
  color: #444;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
  font-family: "Manrope", sans-serif;
}
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: white;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #7e8993;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
#app {
  height: 100%;
}
@font-face {
  font-family: "Manrope";
  src: url("./../assets/fonts/Manrope-VariableFont_wght.ttf");
}
@font-face {
  font-family: "Manrope Regular";
  src: url("./../assets/fonts/static/Manrope-Regular.ttf");
}
@font-face {
  font-family: "Manrope Light";
  src: url("./../assets/fonts/static/Manrope-Light.ttf");
}
@font-face {
  font-family: "Manrope ExtraLight";
  src: url("./../assets/fonts/static/Manrope-ExtraLight.ttf");
}
@font-face {
  font-family: "Manrope Medium";
  src: url("./../assets/fonts/static/Manrope-Medium.ttf");
}
@font-face {
  font-family: "Manrope SemiBold";
  src: url("./../assets/fonts/static/Manrope-SemiBold.ttf");
}
@font-face {
  font-family: "Manrope Bold";
  src: url("./../assets/fonts/static/Manrope-Bold.ttf");
}
@font-face {
  font-family: "Manrope ExtraBold";
  src: url("./../assets/fonts/static/Manrope-ExtraBold.ttf");
}
// .editor{
// 	display: flex;
// 	width: 100vw;
// 	max-width: 100vw;
// }

#inline-dashboard .uppy-Dashboard-AddFiles {
  display: none !important;
}
// #inline-dashboard .uppy-Dashboard-dropFilesHereHint{
//   display: none;
// }
#inline-dashboard .uppy-DashboardContent-addMore {
  display: none !important;
}
#inline-dashboard {
  position: fixed;
  bottom: 0;
  right: 0;
  width: calc(50% - 300px);
  max-height: 50%;
  overflow-y: scroll;
  z-index: 2;
}

.drag_drop_container
  .uppy-Container.uppy-Root.uppy-Dashboard.uppy-Dashboard-inner.uppy-Dashboard-innerWrap {
  border: 1px outline #7e8993 !important;
}
.uppy-Dashboard-inner {
  background-color: none !important;
}
.drag_drop_container
  .uppy-Container.uppy-Root.uppy-Dashboard.uppy-Dashboard-inner.uppy-Dashboard-innerWrap.uppy-Dashboard-AddFiles.uppy-size--md
  .uppy-Dashboard-AddFiles-title {
  font-size: 12px !important;
}
.drag_drop_container .uppy-Dashboard-AddFiles-title {
  font-size: 10px;
}
// .uppy-Dashboard-AddFiles-title button{
//   background-color: #0BA5EC !important;
//   color: white !important;
//   border-radius: 19px !important;
//   padding: 7px 12px !important;
//   position: absolute !important;
//   top: 70%;
//   left: 44%;
// }